import React from "react";
// @ts-ignore
import { ReactComponent as NothingFoundSVG } from "../../assets/images/404.svg";
import "./nothing-found.scss";

export const NothingFound = (): JSX.Element => {
  return (
    <div className="nothing-found column">
      <div className="nothing-found__content column">
        <NothingFoundSVG />
      </div>
    </div>
  );
};

export default NothingFound;
