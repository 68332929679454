import React, { useEffect, useState } from "react";
import "./extension-generate-actions";
import { FormSelect } from "../../ui";
import { Checkbox, CheckboxProps } from "antd";
// import { sendDataToExtension } from "../../util";
import { useDispatch } from "react-redux";
import { storeSettingsActions } from "../../store/slices/settings";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const INTONATIONS_DEFAULT_SELECT_OPTIONS: { value: string; label: string }[] = [
  { value: "", label: "Select an intonation" },
  { value: "casualAndFriendly", label: "Casual and Friendly" },
  { value: "strictlyProfessional", label: "Strictly Professional" },
  { value: "creativeAndEngaging", label: "Creative and Engaging" },
  { value: "enthusiasticAndEnergetic", label: "Enthusiastic and Energetic" },
  {
    value: "empatheticAndUnderstanding",
    label: "Empathetic and Understanding",
  },
];

export const ExtensionGenerateActions = (): JSX.Element => {
  const dispatch = useDispatch();
  const intonation = useSelector(
    (state: RootState) => state.settings.intonation
  );
  const useUpwexAI = useSelector(
    (state: RootState) => state.settings.useUpwexAI
  );
  const useSmiles = useSelector((state: RootState) => state.settings.useSmiles);

  const [checkedUpwexAI, setCheckedUpwexAI] = useState(true);
  const [checkedSmiles, setCheckedSmiles] = useState(false);
  const [selectedIntonationId, setSelectedIntonationId] = useState<string>("");

  useEffect(() => {
    setCheckedUpwexAI(useUpwexAI);
  }, [useUpwexAI]);

  useEffect(() => {
    setCheckedSmiles(useSmiles);
  }, [useSmiles]);

  useEffect(() => {
    setSelectedIntonationId(intonation);
  }, [intonation]);

  const onChangeUpwexAI: CheckboxProps["onChange"] = (e) => {
    setCheckedUpwexAI(e.target.checked);
    dispatch(storeSettingsActions.setUseUpwexAI(e.target.checked));
  };

  const onChangeSmiles: CheckboxProps["onChange"] = (e) => {
    setCheckedSmiles(e.target.checked);
    dispatch(storeSettingsActions.setUseSmiles(e.target.checked));
  };

  const handleSelectedIntonationId = (id: string) => {
    setSelectedIntonationId(id);
    dispatch(storeSettingsActions.setIntonation(id));
  };

  return (
    <div className="extension-cover-letter__sub-actions">
      <Checkbox checked={checkedUpwexAI} onChange={onChangeUpwexAI}>
        Use Upwex AI
      </Checkbox>
      {checkedUpwexAI && (
        <>
          <Checkbox checked={checkedSmiles} onChange={onChangeSmiles}>
            Add Smiles
          </Checkbox>
          <FormSelect
            value={selectedIntonationId}
            placeholder="None"
            options={INTONATIONS_DEFAULT_SELECT_OPTIONS}
            onChange={(id) => handleSelectedIntonationId(id)}
          />
        </>
      )}
    </div>
  );
};
