import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExtensionProposal, IPrompt } from "../../data-access";

interface ISettingsState {
  prompts: IPrompt[];
  job: IExtensionProposal;
  promptText: string;
  promptEditabledText: string;
  promptId: string;
  intonation: string;
  useUpwexAI: boolean;
  useSmiles: boolean;
}

const INITIAL_STATE: ISettingsState = {
  job: {},
  prompts: [],
  promptText: "",
  promptEditabledText: "",
  promptId: "",
  useUpwexAI: false,
  useSmiles: false,
  intonation: "",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  reducers: {
    setPrompts: (state = INITIAL_STATE, action: PayloadAction<IPrompt[]>) => {
      return {
        ...state,
        prompts: action.payload,
      };
    },
    setJob: (
      state = INITIAL_STATE,
      action: PayloadAction<IExtensionProposal>
    ) => {
      return {
        ...state,
        job: action.payload,
      };
    },
    setPromptText: (state = INITIAL_STATE, action: PayloadAction<string>) => {
      return {
        ...state,
        promptText: action.payload,
      };
    },
    setPromptEditabledText: (
      state = INITIAL_STATE,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        promptEditabledText: action.payload,
      };
    },
    setUseUpwexAI: (state = INITIAL_STATE, action: PayloadAction<boolean>) => {
      return {
        ...state,
        useUpwexAI: action.payload,
      };
    },
    setUseSmiles: (state = INITIAL_STATE, action: PayloadAction<boolean>) => {
      return {
        ...state,
        useSmiles: action.payload,
      };
    },
    setIntonation: (state = INITIAL_STATE, action: PayloadAction<string>) => {
      return {
        ...state,
        intonation: action.payload,
      };
    },
    setPromptId: (state = INITIAL_STATE, action: PayloadAction<string>) => {
      return {
        ...state,
        promptId: action.payload,
      };
    },
    updateSettings: (
      state = INITIAL_STATE,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetSettingsData: () => ({ ...INITIAL_STATE }),
  },
});

export const storeSettingsActions = settingsSlice.actions;

const settingsReducer = settingsSlice.reducer;

export default settingsReducer;
