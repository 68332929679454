import React, { useEffect } from "react";
import "./extension-user-subscription.scss";
import { ExtensionAIRequests } from "../extension-ai-requests";
import { ExtensionTableItem } from "../extension-table-item";
import { IUser } from "../../data-access";

interface IExtensionUserSubscriptionProps {
  user: any;
}

export const ExtensionUserSubscription = ({
  user,
}: IExtensionUserSubscriptionProps): JSX.Element => {
  // const { currentUser } = useUser();
  // const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      // usersApi.getMe().then((response: { data: IUser }) => {
      //   const userData = { user: response.data };
      //   // dispatch(storeAuthActions.setUserData(userData));
      // });
    }
  }, []);

  return user?.license ? (
    <div className="extension-block g-4">
      <ExtensionAIRequests
        value={user.license?.used_ai_requests}
        maxValue={user.license?.given_ai_requests}
      />
      <ExtensionTableItem label="Plan" value={user.license?.plan} />
      <ExtensionTableItem
        label="License"
        value={user.paid_user ? "Paid" : "Not Paid"}
        colorStatus={user.paid_user ? "success" : "error"}
        isBadge={true}
      />
    </div>
  ) : user.role === "member" ? (
    <div className="extension-block g-4">
      <span className="extension-text">
        No assigned licenses.
        <br />
        Please, contact with your <b>Team</b>
        <br />
        to assign License.
      </span>
    </div>
  ) : (
    <div className="extension-block g-4">
      <span className="extension-text m-b-12">No assigned licenses</span>
      <a className="extension-btn primary-ex-btn">Billing</a>
    </div>
  );
};
