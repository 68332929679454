import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./slices/auth";
import settingsReducer from "./slices/settings";
import { storage } from "../util";

export const appStateKey = "app_state";

// middlewares
const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    storage.set(appStateKey, getState());
    return result;
  };
};

const reHydrateStore = () => {
  const appState = storage.get(appStateKey) as any;
  // TODO: create mechanism to skip reducers from being synced with localStorage

  if (appState !== null) {
    return appState;
  }
};

export const store = configureStore({
  reducer: {
    // auth: authReducer,
    settings: settingsReducer,
  },
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(localStorageMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
