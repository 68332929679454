import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import "./prompt-edit-frame.scss";
import { useNavigate } from "react-router-dom";
import {
  IPrompt,
  IPromptRequest,
  IPromptUpdatedRequest,
  IResponseError,
  PostMessageType,
} from "../../data-access";
import { handleFailedRequest, notify, postExtensionMessage } from "../../util";
import { extensionApi } from "../../api";
import { Loader } from "../../ui/loader";
import { FormButton, FormInput } from "../../ui";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { FaAngleLeft } from "react-icons/fa";

interface IPromptFormData {
  name: string;
  prompt: string;
}

const validationScheme = yup
  .object({
    name: yup.string().required(),
    prompt: yup.string().required(),
  })
  .required();

export const PromptEditFrame = (): JSX.Element => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const promptId = queryParams.get("id") || "";
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [isFetchingPrompt, setIsFetchingPrompt] = useState<boolean>(false);
  const [isUpdatingPrompt, setIsUpdatingPrompt] = useState<boolean>(false);

  const fetchPrompts = useCallback((): void => {
    setIsFetchingPrompt(true);

    extensionApi
      .getPrompts(refreshToken)
      .then((response: { data: any[] }) => {
        const prompts = response.data;
        const findedPrompt = prompts.find((pr) => pr.id === promptId);
        reset({
          name: findedPrompt?.name,
          prompt: findedPrompt?.prompt,
        });
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsFetchingPrompt(false));
  }, []);

  useEffect(() => {
    if (refreshToken && promptId) {
      fetchPrompts();
    }
  }, [fetchPrompts]);

  const form = useForm<IPromptFormData>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      prompt: "",
    },
    resolver: yupResolver(validationScheme),
  });
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;

  const onSubmit = (data: IPromptRequest) => {
    promptId ? handlePromptUpdate(data) : handlePromptCreate(data);
  };

  const onBack = (): void => {
    navigate(-1);
  };

  const handlePromptUpdate = (prompt: IPromptRequest) => {
    setIsUpdatingPrompt(true);

    extensionApi
      .updatePrompt(refreshToken, promptId, prompt)
      .then((response: { data: IPromptUpdatedRequest }) => {
        notify("success", "Prompt has been created!");
        onBack();
        postExtensionMessage(
          "prompts-sidebar-event" as PostMessageType,
          response.data
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsUpdatingPrompt(false));
  };

  const handlePromptCreate = (prompt: IPromptRequest) => {
    setIsUpdatingPrompt(true);

    extensionApi
      .createPrompt(refreshToken, prompt)
      .then((response: { data: IPrompt }) => {
        notify("success", "Prompt has been updated!");
        onBack();
        postExtensionMessage(
          "prompts-sidebar-event" as PostMessageType,
          response.data
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsUpdatingPrompt(false));
  };

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/prompt-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">{promptId ? "Edit" : "Create"} Prompt</h3>

      <div className="back-content">
        <button
          className="extension-btn secondary-ex-btn back-btn"
          onClick={onBack}
        >
          <FaAngleLeft />
        </button>
      </div>

      {isFetchingPrompt && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {!isFetchingPrompt && (
        <div className="extension-content">
          <form
            className="invite-member-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="ex-title">Name</p>
            <Controller
              control={control}
              name="name"
              render={({ field: { name, onBlur, value, ref } }) => (
                <FormInput
                  ref={ref}
                  placeholder=""
                  name={name}
                  value={value}
                  disabled={isUpdatingPrompt}
                  onBlur={onBlur}
                  onChange={(e: FocusEvent<HTMLInputElement>) =>
                    setValue("name", e?.target?.value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />

            <p className="ex-title">Prompt</p>
            <Controller
              control={control}
              name="prompt"
              render={({ field: { value } }) => (
                <HighlightWithinTextarea
                  placeholder=""
                  value={value}
                  readOnly={isUpdatingPrompt}
                  highlight={[
                    {
                      highlight: /\[(.*?)\]/g,
                      className: "highlight-text",
                    },
                  ]}
                  onChange={(text: string) =>
                    setValue("prompt", text, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
            <FormButton
              className="extension-form-button primary-ex-btn"
              loading={isUpdatingPrompt}
              disabled={!isValid || isUpdatingPrompt}
              htmlType="submit"
            >
              {promptId ? "Update" : "Create"}
            </FormButton>
          </form>
        </div>
      )}
    </div>
  );
};
