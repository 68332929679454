import React from "react";
import "./extension-crm-item.scss";
import { ExtensionTableItem } from "../extension-table-item";
import { APP_ROUTES, ICrmPreview } from "../../data-access";

interface IExtensionCRMItemProps {
  label: string;
  isEnableItem: boolean;
  isSynced: boolean;
  item: ICrmPreview;
}

const baseUrl = process.env.REACT_APP_URL;

export const ExtensionCRMItem = ({
  label,
  isEnableItem,
  isSynced,
  item,
}: IExtensionCRMItemProps): JSX.Element => {
  const handleOpenUrl = (url: string) => {
    if (isSynced && url) {
      window.open(url, "_blank");
    } else if (!isEnableItem) {
      window.open(`${baseUrl}${APP_ROUTES.settings}`, "_blank");
    }
  };

  return (
    <div className="extension-block g-4">
      <ExtensionTableItem label={label}>
        {isEnableItem && (
          <p className={`ex-title ${item.subname && "subname"}`}>
            {item.name}
            {item.subname && <span> | {item.subname}</span>}
          </p>
        )}
        {!isEnableItem && (
          <p className="ex-enable-title">
            To enable the creation of a {label}, go to the settings
          </p>
        )}
      </ExtensionTableItem>
      {item?.stage && (
        <ExtensionTableItem
          label="License"
          value={item?.stage}
          colorStatus={"success"}
          isBadge={true}
        />
      )}
      <button
        className="extension-btn primary-ex-btn"
        disabled={!isSynced || (!item.url && isEnableItem)}
        onClick={() => handleOpenUrl(item.url)}
      >
        {isEnableItem ? "Open in CRM" : "Settings"}
      </button>
    </div>
  );
};
