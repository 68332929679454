import React, { useCallback, useEffect, useState } from "react";
import "./cover-letter-frame";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
// @ts-ignore
import { ReactComponent as MagicSVG } from "../../assets/images/magic.svg";
import { extensionApi } from "../../api";
import {
  IJobInfo,
  IPrompt,
  IPromptRequest,
  IPromptUpdatedRequest,
  IUser,
  PostMessageType,
} from "../../data-access";
import { postExtensionMessage } from "../../util";
import { ExtensionGenerateAI } from "../../components/extension-generate-ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { storeSettingsActions } from "../../store/slices/settings";
import { ThreeDots } from "react-loader-spinner";

const DEFAULT_PROMPT_ID = "001";
const DEFAULT_PROMPT_TEXT = `[write a greeting to a client from my country to his country]\n\nI'm [Your Name], a Senior Developer from [write my country], with over 5 years of experience. I am eager to contribute to your project, I am well-versed in [specific aspect of the job posting].\n\n[Check what is the main client's pain point and how I can help with it]\n[Ask an engaging question related to the project to kickstart the conversation]\n[Highlight my qualifications and unique selling point]\n\nI offer flexible availability for your project. To showcase my skills, I'm happy to perform several test tasks free of charge. You can view my diverse portfolio in my profile.\n\nLooking forward to a potential long-term collaboration. Please feel free to contact me to discuss further.\n\nBest regards,\n[Your Name]`;

export const CoverLetterFrame = (): JSX.Element => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const checkedSmiles = useSelector(
    (state: RootState) => state.settings.useSmiles
  );
  const useUpwexAI = useSelector(
    (state: RootState) => state.settings.useUpwexAI
  );
  const text = useSelector((state: RootState) => state.settings.promptText);
  const selectedIntonationId = useSelector(
    (state: RootState) => state.settings.intonation
  );
  const selectedPromptId = useSelector(
    (state: RootState) => state.settings.promptId
  );
  const jobInfo = useSelector((state: RootState) => state.settings.job);
  const promptEditabledText = useSelector(
    (state: RootState) => state.settings.promptEditabledText
  );
  const prompts = useSelector((state: RootState) => state.settings.prompts);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGeneration, setIsGeneration] = useState<boolean>(false);
  const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
  const [generateError, setGenerateError] = useState<string>("");
  const [userInfo, setUserInfo] = useState<IUser>({});

  const fetchPrompts = useCallback((): void => {
    setIsLoading(true);

    extensionApi
      .getPrompts(refreshToken)
      .then((response: { data: IPrompt[] }) => {
        dispatch(storeSettingsActions.setPrompts(response.data));
      })
      .finally(() => setIsLoading(false));
  }, []);

  const getMe = useCallback((): void => {
    setIsLoading(true);

    extensionApi
      .getMe(refreshToken)
      .then((response: { data: IUser }) => {
        setUserInfo(response.data);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500));
  }, []);

  useEffect(() => {
    const extensionSettings: { [key: string]: string | boolean } = {
      useSmiles: checkedSmiles || false,
      useUpwexAI: useUpwexAI || true,
      prompt: text || "",
      intonation: selectedIntonationId || "",
      promptId: selectedPromptId || "",
    };

    postExtensionMessage(
      "cover-letter-settings" as PostMessageType,
      extensionSettings
    );
  }, [useUpwexAI, text, checkedSmiles, selectedIntonationId, selectedPromptId]);

  useEffect(() => {
    if (refreshToken) {
      fetchPrompts();
      getMe();
    }
  }, [fetchPrompts, getMe]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "sent-job-info") {
        const messageData: IJobInfo = event.data.data;

        dispatch(
          storeSettingsActions.updateSettings({
            job: {
              title: messageData.title,
              description: messageData.description,
              clientCountry: messageData.clientCountry,
              jobId: messageData.jobId,
            },
            useSmiles: messageData.useSmiles || false,
            useUpwexAI: messageData.useUpwexAI || true,
            intonation: messageData.intonation || "",
            promptId: messageData.promptId || DEFAULT_PROMPT_ID,
            promptText: messageData.prompt || DEFAULT_PROMPT_TEXT,
            promptEditabledText: messageData.prompt || DEFAULT_PROMPT_TEXT,
          })
        );
      }

      if (event.data.type === "prompts-sidebar-updated") {
        const eventData = event.data.data;
        const eventPromptData: IPrompt = eventData.data;
        const eventPromptsData: IPrompt[] = eventData.prompts || [];

        if (selectedPromptId === eventPromptData.id) {
          dispatch(storeSettingsActions.setPromptText(eventPromptData.prompt));
          dispatch(
            storeSettingsActions.setPromptEditabledText(eventPromptData.prompt)
          );
        }

        if (eventPromptsData && eventPromptsData.length) {
          dispatch(storeSettingsActions.setPrompts(eventPromptsData));
        } else {
          const newPrompts = [...prompts, eventPromptData];
          dispatch(storeSettingsActions.setPrompts(newPrompts));
        }
      }

      if (event.data.type === "prompts-sidebar-deleted") {
        const eventPromptData: { promptId: string } = event.data.data;
        const filteredPrompts: IPrompt[] = prompts.filter(
          (prompt: IPrompt) => prompt.id !== eventPromptData.promptId
        );
        if (eventPromptData.promptId === selectedPromptId) {
          dispatch(
            storeSettingsActions.updateSettings({
              promptId: prompts[0].id,
              promptText: prompts[0].prompt,
              promptEditabledText: prompts[0].prompt,
            })
          );
        }
        dispatch(storeSettingsActions.setPrompts(filteredPrompts));
      }

      if (event.data.type === "updating-upwork-profile") {
        getMe();
      }

      if (event.data.type === "upwork-agency-checking") {
        const agencyUser = event.data.data;
        handleGenerate(agencyUser);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [
    selectedPromptId,
    prompts,
    useUpwexAI,
    promptEditabledText,
    checkedSmiles,
    selectedIntonationId,
  ]);

  const handleOpenFullPanel = (): void => {
    setIsOpenPanel(!isOpenPanel);

    postExtensionMessage("cover-letter-height" as PostMessageType, {
      isFullyHeight: !isOpenPanel,
    });
  };

  const handleUpdatePrompt = () => {
    setIsGeneration(true);
    const updatingPrompt: IPromptRequest = {
      name: prompts?.find((pr) => pr.id === selectedPromptId)?.name || "",
      prompt: promptEditabledText,
    };
    extensionApi
      .updatePrompt(refreshToken, selectedPromptId, updatingPrompt)
      .then((response: { data: IPromptUpdatedRequest }) => {
        dispatch(
          storeSettingsActions.updateSettings({
            promptId: response.data.data.id,
            promptText: response.data.data.prompt,
            promptEditabledText: response.data.data.prompt,
            prompts: response.data.prompts,
          })
        );
      })
      .finally(() => setIsGeneration(false));
  };

  const handleCheckAgencyUser = () => {
    postExtensionMessage("checking-agency-user" as PostMessageType, null);
  };

  const handleGenerate = (agencyUser?: any) => {
    setGenerateError("");

    let data: any = {
      title: jobInfo.title || "",
      description: jobInfo.description || "",
      clientCountry: jobInfo.clientCountry || "",
      proposalEditableText: promptEditabledText,
      useSmiles: checkedSmiles,
      intonation: selectedIntonationId,
    };

    if (agencyUser) {
      data["upworkUser"] = {
        agencyUserId: agencyUser.agencyUserId,
        agencyUsername: agencyUser.agencyUsername,
      };
    }

    if (useUpwexAI) {
      setIsGeneration(true);
      extensionApi
        .sendGenerateAIProposal(refreshToken, data)
        .then((response: { data: any }) => {
          postExtensionMessage("cover-letter-proposal" as PostMessageType, {
            text: response.data.choices[0].message.content,
          });
          fetchGeneratedAIProposalStatistic();
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            setGenerateError(error.response.data.message);
          }
        })
        .finally(() => setIsGeneration(false));
    } else {
      postExtensionMessage("cover-letter-proposal" as PostMessageType, {
        text: data.proposalEditableText,
      });
    }
  };

  const fetchGeneratedAIProposalStatistic = () => {
    if (jobInfo.jobId) {
      extensionApi.sendInsightsAIProposal(refreshToken, jobInfo.jobId).then();
    }
  };

  return (
    <div className="extension-cover-letter">
      <div className="extension-cover-letter__actions">
        <div className="extension-cover-letter__action-btn">
          <button
            disabled={!refreshToken || !userInfo.upwork_profile?.id}
            className="extension-btn primary-ex-btn"
            onClick={handleGenerate}
          >
            <MagicSVG />
            {useUpwexAI ? "Generate AI Proposal" : "Insert My Proposal"}
          </button>

          {userInfo.id && !userInfo.upwork_profile?.id && !isLoading ? (
            <span>Please, connect your Upwork account</span>
          ) : (
            <></>
          )}

          {generateError && !isLoading ? <span>{generateError}</span> : <></>}
        </div>

        <button
          disabled={!refreshToken}
          className="extension-btn primary-ex-btn minify-btn"
          onClick={handleOpenFullPanel}
        >
          {!isOpenPanel ? <FaAngleDown /> : <FaAngleUp />}
        </button>
      </div>

      {(isLoading || isGeneration) && (
        <div className="frame-loader">
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#13544e"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      {isOpenPanel && !isLoading && (
        <ExtensionGenerateAI onUpdate={handleUpdatePrompt} />
      )}
    </div>
  );
};
