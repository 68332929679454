import React, { useEffect, useState } from "react";
import "./analytics-frame.scss";

import { IUser } from "../../data-access";
import { extensionApi } from "../../api/extension-api";
import { ExtensionAnalytics } from "../../components/extension-analytics";
import { Loader } from "../../ui/loader";

export const AnalyticsFrame = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  useEffect(() => {
    setIsLoading(true);

    extensionApi
      .getMe(refreshToken)
      .then((response: { data: IUser }) => {
        setCurrentUser(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [refreshToken]);

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/statistic-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">Analytics</h3>

      <div className="extension-content">
        {isLoading && (
          <div className="frame-loader">
            <Loader />
          </div>
        )}

        {!isLoading && currentUser && currentUser.id && (
          <ExtensionAnalytics
            isFullAnalytics={true}
            refreshToken={refreshToken}
            currentUser={currentUser}
          />
        )}
      </div>
    </div>
  );
};
