import React, { useCallback, useEffect, useState } from "react";

import "./deals-frame.scss";
import { ExtensionTableItem } from "../../components";
import { IDeal } from "../../data-access";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";

export const DealsFrame = (): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [deals, setDeals] = useState<any[]>([]);
  const [isFetchingDeals, setIsFetchingDeals] = useState<boolean>(false);

  const fetchSettings = useCallback((): void => {
    setIsFetchingDeals(true);

    extensionApi
      .getDeals(refreshToken)
      .then((response: { data: IDeal[] }) => {
        setDeals(response.data);
      })
      .finally(() => setIsFetchingDeals(false));
  }, []);

  useEffect(() => {
    if (refreshToken) {
      fetchSettings();
    }
  }, [fetchSettings]);

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/deals-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">Deals</h3>

      {isFetchingDeals && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {!isFetchingDeals && (
        <div className="extension-content">
          {deals.map((deal) => (
            <div className="extension-block g-4">
              <ExtensionTableItem label="Deal Name">
                <p className="ex-title">{deal.title}</p>
              </ExtensionTableItem>
              <ExtensionTableItem
                label="Stage"
                value={deal.stage}
                colorStatus="success"
                isBadge={true}
              />
              <a
                className="extension-btn primary-ex-btn"
                target="_blank"
                href={deal.url}
              >
                Open Deal
              </a>
            </div>
          ))}

          {!deals.length && (
            <div className="extension-block g-4">
              <span className="extension-text">Deals not found.</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
