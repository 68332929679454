import axios from "axios";
import {
  AnalyticsPeriod,
  IAnalyticsResponse,
  ICrmSettings,
  IGetSettingsResponse,
  IJobAISettings,
  IPromptRequest,
  IPromptUpdatedRequest,
  IUser,
} from "../data-access";

export const baseApiPath = process.env.REACT_APP_API_ENDPOINT;

const extensionClient = (token: string) => {
  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "ext-version": "",
    },
  };

  return {
    get: (url: string, options = {}) =>
      axios.get(url, { ...defaultOptions, ...options }).then((response) => {
        return Promise.resolve(response.data);
      }),
    post: (url: string, data: any, options = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url: string, data: any, options = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    patch: (url: string, data: any, options = {}) =>
      axios.patch(url, data, { ...defaultOptions, ...options }),
    delete: (url: string, options = {}) =>
      axios.delete(url, { ...defaultOptions, ...options }),
  };
};

const getUpworkProfiles = (token: string): Promise<{ data: any[] }> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/upwork/profiles`);
};

const setUpworkProfile = (
  token: string,
  profile: any
): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/upwork/profiles`, profile);
};

const updateUpworkProfile = (
  token: string,
  profileId: string,
  profile: any
): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.patch(
    `${baseApiPath}/api/upwork/profiles/${profileId}`,
    profile
  );
};

const makeUpworkProfileActive = (
  token: string,
  profileId: string
): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.patch(
    `${baseApiPath}/api/upwork/profiles/${profileId}/makeActive`,
    null
  );
};

const deleteUpworkProfile = (
  token: string,
  profileId: string
): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.delete(`${baseApiPath}/api/upwork/profiles/${profileId}`);
};

const getMe = (token: string): Promise<{ data: IUser }> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/user`);
};

const getPipedriveStatus = (
  token: string
): Promise<{ data: { is_connected: boolean } }> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/tools-and-apps/pipedrive/exist`);
};

const getSettings = (token: string): Promise<IGetSettingsResponse> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/settings`);
};

const updateSettings = (
  token: string,
  settings: ICrmSettings
): Promise<{ data: IGetSettingsResponse }> => {
  const request = extensionClient(token);
  return request.patch(`${baseApiPath}/api/settings/crm`, settings);
};

const updateAIJobSettings = (
  token: string,
  settings: IJobAISettings
): Promise<{ data: IGetSettingsResponse }> => {
  const request = extensionClient(token);
  return request.patch(`${baseApiPath}/api/settings/job-assistant`, settings);
};

const getUserAnalytics = ({
  token,
  id,
  period,
}: {
  token: string;
  id: string;
  period: AnalyticsPeriod;
}): Promise<{ data: IAnalyticsResponse }> => {
  const request = extensionClient(token);
  const userIdParam = id ? `&user_id=${id}` : "";
  return request.get(
    `${baseApiPath}/api/insights/get-insights?time_period=${period}${userIdParam}`
  );
};

const getDeals = (token: string): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/crm/get-deals`);
};

const getPrompts = (token: string): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/chat-gpt/prompts`);
};

const deletePrompt = (token: string, id: string): Promise<{ data: any }> => {
  const request = extensionClient(token);
  return request.delete(`${baseApiPath}/api/chat-gpt/prompts/${id}`);
};

const updatePrompt = (
  token: string,
  id: string,
  propmt: IPromptRequest
): Promise<{ data: IPromptUpdatedRequest }> => {
  const request = extensionClient(token);
  return request.put(`${baseApiPath}/api/chat-gpt/prompts/${id}`, propmt);
};

const createPrompt = (token: string, propmt: IPromptRequest): Promise<any> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/chat-gpt/prompts`, propmt);
};

const getPipelines = (token: string): Promise<any> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/crm/get-pipelines`);
};

const getSyncStatus = (token: string, roomId: string): Promise<any> => {
  const request = extensionClient(token);
  return request.get(`${baseApiPath}/api/crm/sync/${roomId}/status`);
};

const updateSyncCrm = (
  token: string,
  roomId: string,
  data: any
): Promise<any> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/crm/sync/${roomId}/update`, { data });
};

const createSyncCrm = (token: string, data: any): Promise<any> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/crm/sync`, { data });
};

const generateAIJobAssistant = (token: string, data: any): Promise<any> => {
  if (data && data.title) {
    const request = extensionClient(token);
    return request.post(`${baseApiPath}/api/chat-gpt/job-assistant`, {
      job: data,
    });
  }
  return new Promise((resolve, reject) => {
    return reject();
  });
};

const getSyncMessagesDiff = (
  token: string,
  roomId: string,
  data: any
): Promise<any> => {
  const request = extensionClient(token);
  return request.post(
    `${baseApiPath}/api/crm/sync/${roomId}/get-messages-diff`,
    data
  );
};

const sendGenerateAIProposal = (token: string, data: any): Promise<any> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/chat-gpt/proposal`, data);
};

const sendInsightsAIProposal = (token: string, jobId: string): Promise<any> => {
  const request = extensionClient(token);
  return request.post(`${baseApiPath}/api/insights/ai-proposal`, {
    data: { jobId },
  });
};

export const extensionApi = {
  getMe,
  getPipedriveStatus,
  getSettings,
  updateSettings,
  updateAIJobSettings,
  getUserAnalytics,
  getDeals,
  getPrompts,
  createPrompt,
  updatePrompt,
  deletePrompt,
  getPipelines,
  getSyncStatus,
  getSyncMessagesDiff,
  createSyncCrm,
  updateSyncCrm,
  sendGenerateAIProposal,
  sendInsightsAIProposal,
  getUpworkProfiles,
  setUpworkProfile,
  updateUpworkProfile,
  makeUpworkProfileActive,
  deleteUpworkProfile,
  generateAIJobAssistant,
};
