// interfaces
export interface IResponseError {
  status: number;
  text: string;
  reason?: string;
  message?: string;
  response?: any;
  details?: { [key: string]: string[] };
}

export interface IDeal {
  id: number;
  title: string;
  stage: string;
  url: string;
}

export interface IPrompt {
  id: string;
  name: string;
  prompt: string;
  default: boolean;
}

export interface IExtensionProposal {
  title?: string;
  description?: string;
  clientCountry?: string;
  name?: string;
  userCountry?: string;
  jobId?: string;
  promptId?: string;
}

export interface IJobInfo extends IExtensionProposal {
  useSmiles?: boolean;
  useUpwexAI?: boolean;
  intonation?: string;
  prompt?: string;
}

export interface IPromptUpdatedRequest {
  data: IPrompt;
  prompts: IPrompt[];
}

export interface IPromptRequest {
  name: string;
  prompt: string;
}

export interface ICrmPreview {
  name: string | null;
  subname: string | null;
  stage: string | null;
  url: string;
}

export interface IUser {
  id?: string;
  email?: string;
  team_id?: string;
  invited_by?: string | null;
  paid_user?: boolean;
  automatic_job_ai_assistant?: boolean;
  can_use_pipedrive?: boolean;
  role?: UserStatus;
  full_name?: string;
  picture?: string;
  is_verified?: boolean;
  show_plashka?: boolean;
  upwork_profile?: any;
  sync_start_date?: string | null;
  chat_hash?: string;
  trial_expires_at?: string;
  active_licenses_count?: number;
  domain?: string;
  plan?: BillingPlans;
  teammates?: number;
  teammates_paid?: number;
  trial_date_start?: string;
  trial_date_end?: string;
  plan_start_date?: string;
  plan_expire_date?: string;
  license?: {
    id: string;
    used_ai_requests: number;
    given_ai_requests: number;
    plan: BillingPlans;
    payment_status: string;
    is_stripe: boolean;
  } | null;
  pipedrive_app?: "public" | "private_prod" | "private_dev";
  promocode?: IDiscount | null;
}

export interface IGetPipedriveConnectionResponse {
  data: {
    is_connected: boolean;
  };
}

export interface ICrmSettings {
  auto_create_company: boolean;
  deal_name?: string;
  create_deal: boolean;
  create_all_notes_in_deal: boolean;
  create_lead: boolean;
  create_notes_for_job: boolean;
  create_notes_for_bid: boolean;
  create_notes_for_all_messages: boolean;
  sync_start_date: string;
  ai_model: string;
}
export interface IJobAISettings {
  automatic_job_ai_assistant: boolean;
}

export interface IGetSettingsResponse {
  data: {
    crm: ICrmSettings;
    job_assistant: IJobAISettings;
  };
}

export interface IUpdateSettingsResponse {
  data: ICrmSettings;
}

export interface IAnalytics {
  ai_answers: number;
  ai_proposals: number;
  bids: number;
  opened_jobs: number;
  replies: number;
  reply_rate: number;
  sync_with_crms: number;
  direct_messages: number;
  invites: number;
  used_connects: number;
}

export interface IAnalyticsResponse {
  graph: {
    datasets: {
      opened_jobs: number[];
      bids: number[];
      replies: number[];
      ai_proposals: number[];
      ai_answers: number[];
      sync_with_crms: number[];
      reply_rate: number[];
      direct_messages: number[];
      invites: number[];
      used_connects: number[];
    };
    labels: string[];
  };
  total: IAnalytics;
}

export interface IChatInformation {
  chatId?: string;
  chatType?: string;
  city?: string;
  clientId?: string;
  companyName?: string;
  contractId?: string;
  country?: string;
  coverLetter?: string;
  customerHistory?: IChatCustomerHistory[];
  dateBid?: string;
  datePostedJob?: string;
  freelancerId?: string;
  fullname?: string;
  jobDescription?: string;
  jobId?: string;
  jobTitle?: string;
  messages?: IChatMessage[];
  proposedRate?: string;
  type?: string;
}

export interface IChatCustomerHistory {
  contractorInfo?: {
    accessType?: number;
    ciphertext?: string;
    contractorName?: string;
  };
  endDate?: Date;
  feedback?: {
    comment?: string;
    commentAccess?: number;
    feedback_suppressed?: number;
    response_for_client_feedback?: any;
    response_for_freelancer_feedback?: any;
    score?: number;
  };
  feedbackToClient?: {
    comment?: string;
    commentAccess?: number;
    feedback_suppressed?: number;
    response_for_client_feedback?: any;
    response_for_freelancer_feedback?: any;
    score?: number;
  };
  isEDCReplicated?: boolean;
  isPtcJob?: boolean;
  isPtcPrivate?: boolean;
  jobInfo?: {
    ciphertext?: any;
    recno?: any;
    title?: string;
    uid?: string;
    access?: number;
    type?: number;
  };
  rate?: {
    amount?: number;
    currencyCode?: string;
  };
  startDate?: Date;
  status?: number;
  totalCharge?: number;
  totalHours?: number;
}

export interface IChatMessage {
  dateCreated?: number;
  chatId?: string;
  message?: string;
  messageId?: string;
  userId?: string;
  username?: string;
}

export interface IExtensionUpdateSettings {
  checkedSmiles?: boolean;
  checkedUpwexAI?: boolean;
  selectedIntonationId?: string | null;
  selectedPromptId?: string | null;
  text?: string | null;
}

// types
export type PostMessageType =
  | "cover-letter-proposal"
  | "cover-letter-height"
  | "cover-letter-proposal"
  | "open-prompts-sidebar"
  | "prompts-sidebar-event"
  | "connect-profile"
  | "change-ai-job-height"
  | "checking-agency-user"
  | "prompts-sidebar-delete-event";

export type UserStatus = "owner" | "admin" | "member";

export type BillingPlans =
  | "Starter"
  | "Trial"
  | "Lite"
  | "Year"
  | "Rising Talent";

export interface IDiscount {
  code: string;
  discount_amount: number;
  expire_at: string;
}

export type AnalyticsPeriod =
  | "current_week"
  | "last_week"
  | "current_month"
  | "last_month"
  | "last_3_months"
  | "last_year";

// enums
export enum ERROR_MESSAGES {
  GENERIC = "Something went wrong...Please try again later.",
  ACCESS_CODE_EXPIRED = "Access_code is expired. Please log in again.",
  AUTH_FAIL = "There is an active session already established for this device. Make sure to logout from Chrome extension to proceed.",
  FETCH_PIPEDRIVE_FAIL = "Failed to fetch pipedrive connection status.",
  SESSION_ALREADY_SEIZED = "Auth token is already in use.",
}
