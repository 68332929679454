import React, { useCallback, useEffect, useState } from "react";

import "./prompts-frame.scss";
import { ExtensionTableItem } from "../../components";
import { APP_ROUTES, IResponseError, PostMessageType } from "../../data-access";
import { handleFailedRequest, notify, postExtensionMessage } from "../../util";
import { extensionApi } from "../../api";
import { Loader } from "../../ui/loader";
import { FaPen, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

export const PromptsFrame = (): JSX.Element => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const [prompts, setPrompts] = useState<any[]>([]);
  const [isFetchingPrompts, setIsFetchingPrompts] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [promptId, setPromptId] = useState("Content of the modal");

  const fetchPrompts = useCallback((): void => {
    setIsFetchingPrompts(true);

    extensionApi
      .getPrompts(refreshToken)
      .then((response: { data: any[] }) => {
        const data = response.data.filter((pr) => !pr.default);
        setPrompts(data);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsFetchingPrompts(false));
  }, []);

  useEffect(() => {
    if (refreshToken) {
      fetchPrompts();
    }
  }, [fetchPrompts]);

  const handleEdit = (id: string): void => {
    navigate(`${APP_ROUTES.extensionPromptEdit}?${queryParams}&id=${id}`);
  };

  const handleCreate = (): void => {
    navigate(`${APP_ROUTES.extensionPromptEdit}?${queryParams}`);
  };

  const showModal = (id: string) => {
    setOpen(true);
    setPromptId(id);
  };

  const handleDelete = () => {
    setConfirmLoading(true);

    extensionApi
      .deletePrompt(refreshToken, promptId)
      .then(() => {
        const data = prompts.filter((pr) => pr.id !== promptId);
        setPrompts(data);
        postExtensionMessage(
          "prompts-sidebar-delete-event" as PostMessageType,
          { promptId }
        );
        notify("success", "Prompt has been deleted!");
        setOpen(false);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="extension-container filled prompts-content">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/prompt-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">Prompts</h3>

      <div className="back-content">
        <button
          className="extension-btn primary-ex-btn create-btn"
          onClick={handleCreate}
        >
          <FaPlus />
        </button>
      </div>

      {isFetchingPrompts && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {!isFetchingPrompts && (
        <div className="extension-content">
          {prompts.map((prompt) => (
            <div className="extension-block g-4">
              <div className="extension-table-item">
                <div className="extension-table-item__label-content">
                  <div className="extension-table-item__circle success"></div>
                  <div className="extension-table-item__label">
                    {prompt.name}
                  </div>
                </div>
              </div>
              <div className="extension-table-item">
                <div className="extension-table-item__value">
                  <div className="actions-content">
                    <button
                      className="extension-btn primary-ex-btn"
                      onClick={() => handleEdit(prompt.id)}
                    >
                      <FaPen />
                    </button>
                    <button
                      className="extension-btn error-ex-btn"
                      onClick={() => showModal(prompt.id)}
                    >
                      <FaRegTrashAlt />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            // <div className="extension-block g-4">
            //   <ExtensionTableItem label="Prompt Name">
            //     <p className="ex-title">{prompt.name}</p>
            //   </ExtensionTableItem>
            //   <ExtensionTableItem label="Actions">
            //     <div className="actions-content">
            //       <button
            //         className="extension-btn primary-ex-btn"
            //         onClick={() => handleEdit(prompt.id)}
            //       >
            //         <FaPen />
            //       </button>
            //       <button
            //         className="extension-btn error-ex-btn"
            //         onClick={() => showModal(prompt.id)}
            //       >
            //         <FaRegTrashAlt />
            //       </button>
            //     </div>
            //   </ExtensionTableItem>
            // </div>
          ))}

          {!prompts.length && (
            <div className="extension-block g-4">
              <span className="extension-text">Your prompts not found.</span>
            </div>
          )}
        </div>
      )}

      <Modal
        title="Are you sure you want to delete this prompt?"
        open={open}
        onOk={handleDelete}
        okText="Delete"
        okType="danger"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="cancel-popup"
      ></Modal>
    </div>
  );
};
