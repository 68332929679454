import React from "react";
import "./extension-table-item.scss";

interface IUserProfileFrameProps {
  value?: string;
  label?: string;
  colorStatus?: string;
  isBadge?: boolean;
  children?: React.ReactNode;
}

export const ExtensionTableItem = ({
  label,
  value,
  isBadge,
  colorStatus,
  children,
}: IUserProfileFrameProps): JSX.Element => {
  return (
    <div className="extension-table-item">
      <div className="extension-table-item__label-content">
        <div
          className={`extension-table-item__circle ${colorStatus || "success"}`}
        ></div>
        <div className="extension-table-item__label">{label}</div>
      </div>
      <div className="extension-table-item__value">
        {children}
        {!children && !isBadge && <span>{value}</span>}
        {!children && isBadge && (
          <div
            className={`extension-badge extension-${
              colorStatus || "success"
            }-badge`}
          >
            {value}
          </div>
        )}
      </div>
    </div>
  );
};
