import React, { useState } from "react";
import "./option-frame.scss";

import { Loader } from "../../ui/loader";

// import { IUser } from "../../data-access";
// import { extensionApi } from "../../api";

export const OptionFrame = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const queryParams = new URLSearchParams(window.location.search);
  // const upwexUserStatParam = queryParams.get("upwexUserStat");
  // const user = JSON.parse(upwexUserStatParam || "{}");
  // const refreshToken = user.rt_score;

  // const upworkUserInfoParam = queryParams.get("upworkUserInfo");
  // const upworkUserInfo = JSON.parse(upworkUserInfoParam || "{}");

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (refreshToken) {
  //     extensionApi
  //       .getMe(refreshToken)
  //       .then((response: { data: IUser }) => {
  //         // setCurrentUser(response.data);
  //       })
  //       .finally(() => setIsLoading(false));
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [refreshToken]);

  return (
    <div className="option__container">
      {isLoading && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      <div className="option__header">
        <img src="/upwex-logo.svg" alt="logo" />
      </div>

      <div className="option__content">
        <div className="option__item">
          <div className="option__item-title">Copy data for support:</div>
          <div className="option__item-content">
            <button className="extension-btn primary-ex-btn">Copy</button>
          </div>
        </div>
        <div className="option__item">
          <div className="option__item-title">Clear data:</div>
          <div className="option__item-content">
            <button className="extension-btn primary-ex-btn">Clear</button>
          </div>
        </div>
      </div>
    </div>
  );
};
