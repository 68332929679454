import React, { useEffect, useState } from "react";
import "./user-profile-frame.scss";
import {
  ExtensionTableItem,
  ExtensionUserSubscription,
} from "../../components";
import { FaSignOutAlt } from "react-icons/fa";
import { APP_ROUTES, IUser } from "../../data-access";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";

const baseUrl = process.env.REACT_APP_URL;

export const UserProfileFrame = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  useEffect(() => {
    setIsLoading(true);

    extensionApi
      .getMe(refreshToken)
      .then((response: { data: IUser }) => {
        setCurrentUser(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [refreshToken]);

  const handleLogout = (): void => {
    setCurrentUser(null);
    window.open(`${baseUrl}${APP_ROUTES.exSignUp}`, "_blank");
  };

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/profile-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">User Profile</h3>

      {isLoading && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {currentUser && !isLoading && (
        <div className="extension-content">
          <div className="extension-block g-4">
            <ExtensionTableItem label="Email" value={currentUser.email} />
            <ExtensionTableItem label="Role" value={currentUser.role} />
            <ExtensionTableItem
              label="Team"
              value={`${currentUser.teammates}`}
            />
            <ExtensionTableItem
              label="Promocode"
              value={currentUser.promocode?.code}
            />
          </div>

          <h3 className="extension-subtitle">Billing</h3>
          <ExtensionUserSubscription user={currentUser} />

          <button
            className="extension-btn secondary-ex-btn"
            onClick={handleLogout}
          >
            <FaSignOutAlt />
            Logout
          </button>
        </div>
      )}

      {!currentUser && !isLoading && (
        <div className="extension-content g-12">
          <a
            className="extension-btn primary-ex-btn m-b-32"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.signIn}`}
          >
            Login in Upwex
          </a>
          <span className="extension-sub-text">Don't have an account?</span>
          <a
            className="extension-btn secondary-ex-btn"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.signUp}`}
          >
            Sign Up
          </a>
        </div>
      )}
    </div>
  );
};
