import React from "react";
import "./extension-stats-item.scss";

interface IExtensionStatsItemProps {
  icon: JSX.Element;
  metric: string;
  value: string;
}

export const ExtensionStatsItem = ({
  icon,
  metric,
  value,
}: IExtensionStatsItemProps): JSX.Element => {
  return (
    <div className="extension-stats-item">
      <div className="extension-stats-item__img">{icon}</div>
      <div className="extension-stats-item__content">
        <div className="extension-title-text">{value}</div>
        <div className="extension-text">{metric}</div>
      </div>
    </div>
  );
};
