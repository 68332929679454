import React, { useCallback, useEffect, useState } from "react";

import { ExtensionCRMItem } from "../../components";
import { FormSelect } from "../../ui";
import { extensionApi } from "../../api";
import {
  IChatInformation,
  ICrmSettings,
  IGetSettingsResponse,
  IResponseError,
  PostMessageType,
} from "../../data-access";
import { handleFailedRequest, notify, postExtensionMessage } from "../../util";
import { Loader } from "../../ui/loader";
import "./crm-frame.scss";

export const CRMFrame = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedPipelineId, setSelectedPipelineId] = useState<string>("");
  const [chatInformation, setChatInformation] =
    useState<IChatInformation | null>(null);
  const [pipelinesSelectOptions, setPipelinesSelectOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [syncStatus, setSyncStatus] = useState<any>({});
  const [basedSettings, setBasedSettings] = useState<ICrmSettings>({
    auto_create_company: false,
    create_deal: false,
    create_all_notes_in_deal: false,
    create_lead: false,
    create_notes_for_job: false,
    create_notes_for_bid: false,
    create_notes_for_all_messages: false,
    deal_name: "",
    sync_start_date: "",
    ai_model: "",
  });

  const queryParams = new URLSearchParams(window.location.search);
  const roomId = queryParams.get("roomId") || "";
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  const fetchPipelines = useCallback((): void => {
    setIsLoading(true);
    extensionApi
      .getPipelines(refreshToken)
      .then((response: { data: any[] }) => {
        const options = response.data.map((pipeline) => {
          return {
            value: `${pipeline.id}`,
            label: pipeline.name,
          };
        });
        setPipelinesSelectOptions(options);
        fetchSettings();
        fetchSyncStatus();
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchSettings = useCallback((): void => {
    setIsLoading(true);
    extensionApi
      .getSettings(refreshToken)
      .then((response: IGetSettingsResponse) => {
        setBasedSettings(response.data.crm);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchSyncStatus = useCallback((): void => {
    setIsLoading(true);
    extensionApi
      .getSyncStatus(refreshToken, roomId)
      .then((response: { data: any }) => {
        const statusData = response.data;
        setSyncStatus(statusData);
        setSelectedPipelineId(
          (statusData?.info?.deal?.pipeline?.id &&
            `${statusData?.info?.deal?.pipeline?.id}`) ||
            pipelinesSelectOptions[0]?.value
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "sent-chat-info") {
        setChatInformation(event.data.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (refreshToken && roomId) {
      fetchPipelines();
    } else {
      setIsLoading(false);
    }
  }, [fetchPipelines]);

  const handlePipelineChange = (id: string): void => {
    setSelectedPipelineId(id);
  };

  const handleSendCRM = () => {
    syncStatus.full_sync_needed ? handleSyncCRM() : handleUpdateCRM();
  };

  const handleSyncCRM = () => {
    setIsUpdating(true);
    extensionApi
      .createSyncCrm(refreshToken, {
        ...chatInformation,
        pipelineId: selectedPipelineId || pipelinesSelectOptions[0].value,
      })
      .then((response: { data: any }) => {
        postExtensionMessage("close-sidebar" as PostMessageType, null);
        notify("success", "CRM has been synced!");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsUpdating(false));
  };

  const handleUpdateCRM = () => {
    setIsUpdating(true);
    extensionApi
      .updateSyncCrm(refreshToken, roomId, {
        messages: (chatInformation && chatInformation.messages) || [],
        pipelineId: selectedPipelineId || pipelinesSelectOptions[0].value,
      })
      .then(() => {
        postExtensionMessage("close-sidebar" as PostMessageType, null);
        notify("success", "CRM has been updated!");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsUpdating(false));
  };

  const getDealName = () => {
    const dealNameArr: any = [];
    const settingDealName =
      (basedSettings &&
        basedSettings.deal_name &&
        basedSettings.deal_name.split(" ")) ||
      [];
    settingDealName.forEach((settingDealPath: any) => {
      switch (settingDealPath) {
        case "#contact":
          dealNameArr.push(chatInformation?.fullname || "");
          break;
        case "#firstname":
          dealNameArr.push(chatInformation?.fullname?.split(" ")[0] || "");
          break;
        case "#lastname":
          dealNameArr.push(
            chatInformation?.fullname?.split(" ")?.slice(1)?.join(" ")
          );
          break;
        case "#company":
          dealNameArr.push(chatInformation?.companyName || "");
          break;
        default:
          dealNameArr.push(settingDealPath);
          break;
      }
    });
    return dealNameArr.join(" ");
  };

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/pipedrive-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">CRM</h3>

      <div className="extension-content">
        {(isLoading || isUpdating) && (
          <div className="frame-loader">
            <Loader />
          </div>
        )}
        {!isLoading && roomId && chatInformation && (
          <>
            <div className="crm-content">
              <h2 className="section-title">
                {syncStatus.synced &&
                  `${syncStatus?.info?.contact?.name} ${
                    syncStatus?.info?.organization?.name
                      ? `, ${syncStatus?.info.organization.name}`
                      : ""
                  }`}
                {!syncStatus.synced &&
                  `${chatInformation.fullname}${
                    chatInformation.companyName
                      ? `, ${chatInformation.companyName}`
                      : ""
                  }`}
              </h2>

              {basedSettings.create_deal && (
                <FormSelect
                  value={selectedPipelineId || pipelinesSelectOptions[0].value}
                  placeholder="None"
                  options={pipelinesSelectOptions}
                  onChange={(id) => handlePipelineChange(id)}
                />
              )}

              <button
                className="extension-btn primary-ex-btn"
                onClick={handleSendCRM}
              >
                {!syncStatus.synced ? "Sync with Pipedrive" : "Update"}
              </button>
            </div>

            <h2 className="section-title">Preview</h2>
            <ExtensionCRMItem
              label="Deal"
              isEnableItem={basedSettings.create_deal}
              isSynced={syncStatus.synced}
              item={{
                name: syncStatus?.info?.deal?.title || getDealName(),
                subname: chatInformation?.jobTitle || null,
                stage: syncStatus?.info?.deal?.stage || null,
                url: syncStatus?.info?.deal?.url || "",
              }}
            />

            <ExtensionCRMItem
              label="Lead"
              isEnableItem={basedSettings.create_lead}
              isSynced={syncStatus.synced}
              item={{
                name:
                  syncStatus?.info?.lead?.title ||
                  `${
                    chatInformation?.fullname
                      ? chatInformation?.fullname + ", "
                      : ""
                  }${chatInformation?.companyName} lead`,
                subname: null,
                stage: null,
                url: syncStatus?.info?.lead?.url || "",
              }}
            />

            <ExtensionCRMItem
              label="Contact"
              isEnableItem={basedSettings.auto_create_company}
              isSynced={syncStatus.synced}
              item={{
                name:
                  syncStatus?.info?.contact?.name ||
                  chatInformation?.companyName ||
                  chatInformation?.fullname,
                subname: null,
                stage: null,
                url: syncStatus?.info?.contact?.url || "",
              }}
            />

            <ExtensionCRMItem
              label="Organization"
              isEnableItem={basedSettings.auto_create_company}
              isSynced={syncStatus.synced}
              item={{
                name:
                  syncStatus?.info?.organization?.name ||
                  chatInformation?.companyName ||
                  chatInformation?.fullname,
                subname: null,
                stage: "",
                url: syncStatus?.info?.organization?.url || "",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
