import React, { useEffect, useState } from "react";
import {
  FaBriefcase,
  FaComments,
  FaConnectdevelop,
  FaEnvelope,
  FaMoneyBill,
  FaPercent,
  FaRobot,
  FaRocket,
  FaSyncAlt,
  FaTachometerAlt,
  FaTrophy,
  FaUserPlus,
} from "react-icons/fa";
import "./extension-analytics.scss";
import {
  APP_ROUTES,
  AnalyticsPeriod,
  IAnalyticsResponse,
  IResponseError,
  IUser,
} from "../../data-access";
import { handleFailedRequest } from "../../util";
import { extensionApi } from "../../api/extension-api";
import { ExtensionStatsItem } from "../extension-stats-item";
import { Loader } from "../../ui/loader";
import { ExtensionAnalyticsChart } from "../extension-analytics-chart";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";

interface IExtensionAIRequestsProps {
  refreshToken: string;
  currentUser: IUser | null;
  isFullAnalytics?: boolean;
}

const baseUrl = process.env.REACT_APP_URL;
const DEFAULT_SWITCH_SETTINGS_OPTIONS = [
  {
    label: "Current Week",
    value: "current_week",
  },
  {
    label: "Last Week",
    value: "last_week",
  },
];

export const ExtensionAnalytics = ({
  refreshToken,
  currentUser,
  isFullAnalytics = true,
}: IExtensionAIRequestsProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(true);
  const [switchSettings, setSwitchSettings] = useState<string>("current_week");
  const [analytics, setAnalytics] = useState<IAnalyticsResponse>({
    total: {
      ai_answers: 0,
      ai_proposals: 0,
      bids: 0,
      opened_jobs: 0,
      replies: 0,
      reply_rate: 0,
      sync_with_crms: 0,
    },
  } as IAnalyticsResponse);

  const formattedReplyRate = analytics?.total?.reply_rate
    ? Number(analytics.total.reply_rate).toFixed(2)
    : 0;

  const calculateReplyCost = (usedConnects = 0, replies = 0): string => {
    return replies > 0
      ? (((usedConnects / 300) * 45) / replies).toFixed(2)
      : "0";
  };

  const calculateAvgConnects = (usedConnects = 0, bids = 0): string => {
    return bids > 0 ? (usedConnects / bids).toFixed(2) : "0";
  };

  useEffect(() => {
    setIsUpdating(true);

    extensionApi
      .getUserAnalytics({
        token: refreshToken,
        id: currentUser?.id || "",
        period: isFullAnalytics
          ? (switchSettings as AnalyticsPeriod)
          : ("current_month" as AnalyticsPeriod),
      })
      .then((response: { data: IAnalyticsResponse }) => {
        setAnalytics(response.data);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => {
        setIsLoading(false);
        setIsUpdating(false);
      });
  }, [switchSettings]);

  return (
    (!isLoading && (
      <>
        {isFullAnalytics && (
          <>
            <Segmented
              options={DEFAULT_SWITCH_SETTINGS_OPTIONS}
              onChange={(value: SegmentedValue) =>
                setSwitchSettings(value as AnalyticsPeriod)
              }
            />
          </>
        )}

        <div className="analytics-container">
          {isUpdating && (
            <div className="frame-loader">
              <Loader />
            </div>
          )}

          {isFullAnalytics && <ExtensionAnalyticsChart analytics={analytics} />}

          <div className="extension-profile-stats">
            <ExtensionStatsItem
              icon={<FaBriefcase />}
              metric="Open Job"
              value={`${analytics?.total?.opened_jobs ?? 0}`}
            />
            <ExtensionStatsItem
              icon={<FaTrophy />}
              metric="BID"
              value={`${analytics?.total?.bids ?? 0}`}
            />
            <ExtensionStatsItem
              icon={<FaEnvelope />}
              metric="Reply"
              value={`${analytics?.total?.replies ?? 0}`}
            />
            <ExtensionStatsItem
              icon={<FaPercent />}
              metric="Reply Rate"
              value={`${formattedReplyRate}`}
            />
            {isFullAnalytics && (
              <>
                <ExtensionStatsItem
                  icon={<FaUserPlus />}
                  metric="Invites"
                  value={`${analytics?.total?.invites ?? 0}`}
                />
                <ExtensionStatsItem
                  icon={<FaConnectdevelop />}
                  metric="Connects"
                  value={`${analytics?.total?.used_connects ?? 0}`}
                />
                <ExtensionStatsItem
                  icon={<FaTachometerAlt />}
                  metric="AVG Connects"
                  value={calculateAvgConnects(
                    analytics?.total?.used_connects,
                    analytics?.total?.bids
                  )}
                />
                <ExtensionStatsItem
                  icon={<FaMoneyBill />}
                  metric="Reply Cost ($)"
                  value={calculateReplyCost(
                    analytics?.total?.used_connects,
                    analytics?.total?.replies
                  )}
                />
                <ExtensionStatsItem
                  icon={<FaComments />}
                  metric="Direct Messages"
                  value={`${analytics?.total?.direct_messages ?? 0}`}
                />
                <ExtensionStatsItem
                  icon={<FaRobot />}
                  metric="AI Proposal"
                  value={`${analytics?.total?.ai_proposals ?? 0}`}
                />
                <ExtensionStatsItem
                  icon={<FaRocket />}
                  metric="AI Q/A"
                  value={`${analytics?.total?.ai_answers ?? 0}`}
                />
                <ExtensionStatsItem
                  icon={<FaSyncAlt />}
                  metric="Sync with CRM"
                  value={`${analytics?.total?.sync_with_crms ?? 0}`}
                />
              </>
            )}
          </div>

          <a
            className="extension-btn secondary-ex-btn"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.dashboard}`}
          >
            <img src="/statistic-icon.svg" />
            See full analytics
          </a>
        </div>
      </>
    )) || (
      <div className="frame-loader">
        <Loader />
      </div>
    )
  );
};
