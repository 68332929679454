import React, { useCallback, useEffect, useState } from "react";
import "./extension-settings.scss";
import {
  APP_ROUTES,
  ERROR_MESSAGES,
  IGetPipedriveConnectionResponse,
  IUser,
} from "../../data-access";
import { ExtensionCrmSettingsForm } from "../extension-crm-settings-form";
import { notify } from "../../util";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";
import { ExtensionAIJobSettingsForm } from "../extension-ai-job-settings-form";

const baseUrl = process.env.REACT_APP_URL;

interface IExtensionSettingsProps {
  currentUser: IUser;
  refreshToken: string;
}

export const ExtensionSettings = ({
  currentUser,
  refreshToken,
}: IExtensionSettingsProps): JSX.Element => {
  const [isFetchingPipedriveConnection, setIsFetchingPipedriveConnection] =
    useState<boolean>(true);
  const [isPipedriveConnected, setIsPipedriveConnected] = useState<
    boolean | undefined
  >(undefined);

  const fetchPipedriveConnectionStatus = useCallback((): void => {
    extensionApi
      .getPipedriveStatus(refreshToken)
      .then((response: IGetPipedriveConnectionResponse) => {
        setIsPipedriveConnected(response.data.is_connected);
      })
      .catch(() => notify("error", ERROR_MESSAGES.FETCH_PIPEDRIVE_FAIL))
      .finally(() => setIsFetchingPipedriveConnection(false));
  }, []);

  useEffect(() => {
    fetchPipedriveConnectionStatus();
  }, [fetchPipedriveConnectionStatus]);

  return (
    <>
      {isFetchingPipedriveConnection && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}
      {!isFetchingPipedriveConnection ? (
        <div className="extension-content">
          <h3 className="extension-subtitle">AI Job Assistant</h3>
          <ExtensionAIJobSettingsForm refreshToken={refreshToken} />

          <h3 className="extension-subtitle">CRM Settings</h3>
          {currentUser && !currentUser?.can_use_pipedrive && (
            <div className="extension-block">
              <span className="extension-text">
                This functionality is not available for your plan. Please
                upgrade your plan to unlock it.
              </span>
              <a
                className="extension-btn primary-ex-btn"
                target="_blank"
                href={`${baseUrl}${APP_ROUTES.billing}`}
              >
                Upgrade
              </a>
            </div>
          )}

          {currentUser &&
            currentUser?.can_use_pipedrive &&
            !isPipedriveConnected && (
              <div className="extension-block">
                <span className="extension-text">
                  Please connect your CRM to view the settings.
                </span>
                <a
                  className="extension-btn primary-ex-btn"
                  target="_blank"
                  href={`${baseUrl}${APP_ROUTES.toolsAndApps}`}
                >
                  Connect
                </a>
              </div>
            )}

          {currentUser?.can_use_pipedrive && isPipedriveConnected && (
            <ExtensionCrmSettingsForm
              refreshToken={refreshToken}
              isPipedriveConnected={isPipedriveConnected}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
