import PropTypes from "prop-types";
import { MutatingDots } from "react-loader-spinner";
import "./loader.scss";

interface ILoaderProps {
  height?: string;
  width?: string;
  color?: string;
  secondaryColor?: string;
  radius?: string;
  wrapperClass?: string;
}

export const Loader = ({
  height = "100",
  width = "100",
  color = "#13544E",
  secondaryColor = "#13544E",
  radius = "12.5",
  wrapperClass = "",
}: ILoaderProps): JSX.Element => {
  return (
    <MutatingDots
      visible={true}
      height={height}
      width={width}
      color={color}
      secondaryColor={secondaryColor}
      radius={radius}
      ariaLabel="mutating-dots-loading"
      wrapperStyle={{}}
      wrapperClass={wrapperClass}
    />
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
};
