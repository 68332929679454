import React from "react";
import "./extension-ai-requests.scss";

interface IExtensionAIRequestsProps {
  value?: number;
  maxValue?: number;
}

export const ExtensionAIRequests = ({
  value,
  maxValue,
}: IExtensionAIRequestsProps): JSX.Element => {
  const width = maxValue && value ? (value * 100) / maxValue : 0;
  const status = width >= 90 ? "error" : width >= 75 ? "warning" : "success";

  return (
    <div className="extension-ai-requests">
      <h3>Available AI Requests</h3>
      <div className="extension-ai-requests__indicator">
        <div
          className={`extension-ai-requests__progress ${status}`}
          style={{ width: `${width}%` }}
        ></div>
      </div>
      <div className="extension-ai-requests__labels">
        <span>0</span>
        <span>{maxValue || "N/A"}</span>
      </div>
    </div>
  );
};
