import React, { useEffect, useState } from "react";
import "./settings-frame.scss";
import { extensionApi } from "../../api/extension-api";
import { IUser } from "../../data-access";
import { ExtensionSettings } from "../../components/extension-settings";
import { Loader } from "../../ui/loader";

export const SettingsFrame = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  useEffect(() => {
    setIsLoading(true);

    extensionApi
      .getMe(refreshToken)
      .then((response: { data: IUser }) => {
        setCurrentUser(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [refreshToken]);

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img src="/settings-icon.svg" />
        </div>
      </div>
      <h3 className="extension-title">Settings</h3>

      {isLoading && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {currentUser && (
        <ExtensionSettings
          refreshToken={refreshToken}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};
