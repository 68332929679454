import React from "react";
import moment from "moment";

import "./extension-analytics-chart.scss";
import { IAnalyticsResponse } from "../../data-access";
import { Tooltip } from "antd";

interface IExtensionAnalyticsChartProps {
  analytics: IAnalyticsResponse;
}

export const ExtensionAnalyticsChart = ({
  analytics,
}: IExtensionAnalyticsChartProps): JSX.Element => {
  let maxValue = 1;
  const labels = analytics.graph.labels;
  const datasets = analytics.graph.datasets;
  const items = labels.map((label: string, index: number) => {
    const openJob = datasets.opened_jobs[index];
    const bid = datasets.bids[index];
    const reply = datasets.replies[index];
    maxValue = Math.max(maxValue, openJob, bid, reply);

    return {
      openJob,
      bid,
      reply,
      label: moment(label).format("DD.MM"),
    };
  });

  const progressLineValue = (value: number): string => {
    return `${((value * 100) / (maxValue + 1)).toFixed(1)}%`;
  };

  return (
    <div className="extension-analytics-chart">
      <div className="extension-analytics-chart__labels">
        <span className="open-job">Open Job</span>
        <span className="bid">BID</span>
        <span className="reply">Reply</span>
      </div>

      <div className="extension-analytics-chart__content">
        {items.map((item) => (
          <div className="extension-analytics-chart__item">
            <div className="extension-analytics-chart__lines">
              <div className="extension-analytics-chart__line">
                <Tooltip
                  title={`Open Jobs: ${item.openJob}`}
                  color="#0b3632"
                  key="#0b3632"
                  arrow={false}
                >
                  <div
                    className="progress-line open-job"
                    style={{ height: progressLineValue(item.openJob) }}
                  ></div>
                </Tooltip>
              </div>
              <div className="extension-analytics-chart__line">
                <Tooltip
                  title={`BIDS: ${item.bid}`}
                  color="#13544E"
                  key="#13544E"
                  arrow={false}
                >
                  <div
                    className="progress-line bid"
                    style={{ height: progressLineValue(item.bid) }}
                  ></div>
                </Tooltip>
              </div>
              <div className="extension-analytics-chart__line">
                <Tooltip
                  title={`Replies: ${item.reply}`}
                  color="#177B72"
                  key="#177B72"
                  arrow={false}
                >
                  <div
                    className="progress-line reply"
                    style={{ height: progressLineValue(item.reply) }}
                  ></div>
                </Tooltip>
              </div>
            </div>
            <div className="extension-analytics-chart__label">{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
