import React from "react";
import "./job-score-details-frame.scss";
import { ExtensionTableItem } from "../../components";

const MAX_SCORE_VALUE = 5;

export const JobScoreDetailsFrame = (): JSX.Element => {
  const queryParams = new URLSearchParams(window.location.search);
  const upworkScoreDetailsParam = queryParams.get("score");
  const upworkJobId = queryParams.get("jobId");
  const upworkScoreDetails = JSON.parse(upworkScoreDetailsParam || "{}");
  const score = upworkScoreDetails?.score || 0;
  const scoreDetails = [
    {
      label: "Payment Verification",
      value: upworkScoreDetails?.details?.paymentVerification || 0,
      bad: 0,
      good: 0.01,
      exellent: 0.1,
    },
    {
      label: "Rating",
      value: upworkScoreDetails?.details?.rating || 0,
      bad: 0,
      good: 0.4,
      exellent: 0.499,
    },
    {
      label: "Avg Rate",
      value: upworkScoreDetails?.details?.rate || 0,
      bad: 0,
      good: 0.5,
      exellent: 1.5,
    },
    {
      label: "Total Reviews",
      value: upworkScoreDetails?.details?.totalReviews || 0,
      bad: 0,
      good: 0.001,
      exellent: 0.201,
    },
    {
      label: "Hire Rate",
      value: upworkScoreDetails?.details?.hireRate || 0,
      bad: 0,
      good: 0.001,
      exellent: 0.2,
    },
    {
      label: "Job Posted & Open Job",
      value: upworkScoreDetails?.details?.jobPostedAndOpen || 0,
      bad: 0,
      good: 0.05,
      exellent: 0.1,
    },
    {
      label: "Total Spent",
      value: upworkScoreDetails?.details?.totalSpent || 0,
      bad: 0,
      good: 0.1,
      exellent: 0.5,
    },
    {
      label: "Job Posting",
      value: upworkScoreDetails?.details?.jobPostDate || 0,
      bad: 0,
      good: 0.5,
      exellent: 0.95,
    },
    {
      label: "Member Since",
      value: upworkScoreDetails?.details?.memberSince || 0,
      bad: 0,
      good: 0.05,
      exellent: 1,
    },
  ];

  const getScoreData = (
    value: number,
    good: number,
    exellent: number,
    isColor: boolean
  ) => {
    if (value >= exellent) {
      return isColor ? "success" : "Exellent";
    } else if (value >= good) {
      return isColor ? "warning" : "Good";
    }
    return isColor ? "error" : "Bad";
  };

  const openProposalPage = (): void => {
    window.open(
      `https://www.upwork.com/ab/proposals/job/~${upworkJobId}/apply/`,
      "_blank"
    );
  };

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <div className="score-content">
            <svg
              width="140"
              height="140"
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="70"
                cy="70"
                r="64"
                stroke="#F4F7FF"
                stroke-width="4"
              />
              <circle
                cx="70"
                cy="70"
                r="64"
                stroke={`${
                  score >= MAX_SCORE_VALUE
                    ? "#13544E"
                    : score > 2
                    ? "#f4e454"
                    : "#f45454"
                }`}
                fill="none"
                stroke-width="4px"
                stroke-linecap="round"
                stroke-dashoffset="0px"
                className="score-content__progress"
                style={{
                  strokeDasharray: `${(score * 400) / MAX_SCORE_VALUE} 999`,
                }}
              />
            </svg>
            <div
              className="score-content__value"
              style={{
                color: `${
                  score >= MAX_SCORE_VALUE
                    ? "#13544E"
                    : score > 2
                    ? "#f4e454"
                    : "#f45454"
                }`,
              }}
            >
              {score > MAX_SCORE_VALUE
                ? MAX_SCORE_VALUE.toFixed(2)
                : score.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <h3 className="extension-title">Score Details:</h3>

      <div className="extension-content">
        <div className="extension-block g-4">
          {scoreDetails.map((item) => (
            <ExtensionTableItem
              label={item.label}
              value={getScoreData(item.value, item.good, item.exellent, false)}
              colorStatus={getScoreData(
                item.value,
                item.good,
                item.exellent,
                true
              )}
              isBadge={true}
            />
          ))}
        </div>

        <button
          className="extension-btn primary-ex-btn"
          onClick={openProposalPage}
        >
          Open Proposal Page
        </button>
      </div>
    </div>
  );
};
