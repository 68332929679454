import React, { FocusEvent, useEffect, useState } from "react";
import "./extension-crm-settings-form.scss";
import { ExtensionTableItem } from "../extension-table-item";
import {
  ICrmSettings,
  IGetSettingsResponse,
  IResponseError,
} from "../../data-access";
import { Controller, useForm } from "react-hook-form";
import { FormButton, FormInput, FormSwitch } from "../../ui";
import { Tag } from "antd";
import { handleFailedRequest, notify } from "../../util";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";

interface ICrmSettingsFormState {
  [key: string]: {
    title: string;
    value: boolean;
    expandable: boolean;
    tooltipText: string;
  };
}

interface IExtensionCrmSettingsFormProps {
  isPipedriveConnected: boolean;
  refreshToken: string;
}

export const ExtensionCrmSettingsForm = ({
  isPipedriveConnected,
  refreshToken,
}: IExtensionCrmSettingsFormProps): JSX.Element => {
  const [isFetchingSettings, setIsFetchingSettings] = useState<boolean>(true);
  const [basedSettings, setBasedSettings] = useState<ICrmSettings>({
    auto_create_company: false,
    create_deal: false,
    create_all_notes_in_deal: false,
    create_lead: false,
    create_notes_for_job: false,
    create_notes_for_bid: false,
    create_notes_for_all_messages: false,
    deal_name: "",
    sync_start_date: "",
    ai_model: "",
  });
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  const [formSettings] = useState<ICrmSettingsFormState>({
    auto_create_company: {
      title: "Create the Contact's Company in the CRM automatically",
      value: basedSettings.auto_create_company,
      expandable: false,
      tooltipText:
        "When adding a contact to your CRM from Upwork, Upwex will also create a company profile for the contact's current company in your CRM (if the company doesn't exist for the client on Upwork, then Upwex will create a company in the CRM with the contact's name).\n",
    },
    create_lead: {
      title: "Create lead or not",
      value: basedSettings.create_lead,
      expandable: false,
      tooltipText:
        "Enable this if you want a lead to be created in your CRM when synchronizing Upwork chat.",
    },
    create_deal: {
      title: "Create deal or not ",
      value: basedSettings.create_deal,
      expandable: true,
      tooltipText:
        "Enable this if you want a deal to be created in your CRM when synchronizing Upwork chat. Each new contract from the same client is a new deal.\n",
    },
    create_notes_for_job: {
      title: "Create notes about Job in Contact or Deal",
      value: basedSettings.create_notes_for_job,
      expandable: false,
      tooltipText:
        "Enable creation of Job Details note. Includes: Job ID, Job Date, Country, City, Job Title, Job Description.\n",
    },
    create_notes_for_bid: {
      title: "Create notes about Bid in Contact or Deal",
      value: basedSettings.create_notes_for_bid,
      expandable: false,
      tooltipText:
        "Enable creation of Bid Details note. Includes: Job ID, Bid Date, Proposed rate, and Cover letter.\n",
    },
    create_notes_for_all_messages: {
      title: "Create notes about All messages in Contact or Deal",
      value: basedSettings.create_notes_for_all_messages,
      expandable: false,
      tooltipText:
        "Enables the creation of a note with the history of all messages.",
    },
  });
  const form = useForm<ICrmSettings>({
    mode: "all",
    defaultValues: {
      auto_create_company: basedSettings.auto_create_company,
      create_lead: basedSettings.create_lead,
      deal_name: basedSettings.deal_name,
      create_deal: basedSettings.create_deal,
      create_notes_for_job: basedSettings.create_notes_for_job,
      create_notes_for_bid: basedSettings.create_notes_for_bid,
      create_notes_for_all_messages:
        basedSettings.create_notes_for_all_messages,
      create_all_notes_in_deal: basedSettings.create_all_notes_in_deal,
    },
  });
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = form;
  const isCreateDealEnabled = watch("create_deal");
  const isCRMElementDisabled = isProcessingForm || !isPipedriveConnected;

  useEffect(() => {
    setIsFetchingSettings(true);
    extensionApi
      .getSettings(refreshToken)
      .then((response: IGetSettingsResponse) => {
        setBasedSettings(response.data.crm);
        reset(response.data.crm);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsFetchingSettings(false));
  }, []);

  const handleFormSubmit = (formFields: ICrmSettings): void => {
    setIsProcessingForm(true);

    extensionApi
      .updateSettings(refreshToken, formFields)
      .then((response: { data: IGetSettingsResponse }) => {
        notify("success", "CRM settings have been updated!");
        setBasedSettings(response.data.data.crm);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  const handleDealNameTagClick = (tag: string): void => {
    if (!isPipedriveConnected) {
      return;
    }
    // replace subsequent spaces with one space
    const currentValue = getValues("deal_name") ?? "";
    const modifiedFieldValue = `${currentValue} ${tag}`
      .replace(/  +/g, " ")
      .trim();
    setValue("deal_name", modifiedFieldValue);
  };

  return (
    <>
      {isFetchingSettings && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}
      {!isFetchingSettings ? (
        <>
          {Object.keys(formSettings).map((key: string) => {
            const settingKey = key as keyof ICrmSettings;

            return (
              <div className="column" key={settingKey}>
                {!formSettings[settingKey]?.expandable && (
                  <div className="extension-block">
                    <ExtensionTableItem label={formSettings[settingKey].title}>
                      <Controller
                        control={control}
                        name={settingKey}
                        render={({ field: { onChange, value, ref } }) => (
                          <FormSwitch
                            className="extension-switch"
                            ref={ref}
                            disabled={isCRMElementDisabled}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            onChange={onChange}
                            isChecked={!!value}
                          />
                        )}
                      />
                    </ExtensionTableItem>
                  </div>
                )}

                {formSettings[settingKey]?.expandable && (
                  <>
                    <div className="extension-block">
                      <ExtensionTableItem
                        label={formSettings[settingKey].title}
                      >
                        <Controller
                          control={control}
                          name={settingKey}
                          render={({ field: { onChange, value, ref } }) => (
                            <FormSwitch
                              ref={ref}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              className="extension-switch"
                              disabled={isCRMElementDisabled}
                              onChange={onChange}
                              isChecked={!!value}
                            />
                          )}
                        />
                      </ExtensionTableItem>
                    </div>

                    {isCreateDealEnabled && (
                      <>
                        <div className="create-deal-section">
                          <h2 className="section-title">Create Deal name *</h2>
                          <FormInput
                            {...register("deal_name", {
                              required: "This field is required",
                            })}
                            value={watch("deal_name")}
                            placeholder="New deal with #contact of #company"
                            error={errors?.deal_name?.message}
                            disabled={isCRMElementDisabled}
                            onChange={(e: FocusEvent<HTMLInputElement>) =>
                              setValue("deal_name", e?.target?.value, {
                                shouldDirty: true,
                                shouldValidate: true,
                                shouldTouch: true,
                              })
                            }
                          />
                          <p className="note">
                            Change the default template for your new deal &nbsp;{" "}
                            <Tag
                              color="green"
                              className="extension-tag"
                              onClick={() => handleDealNameTagClick("#contact")}
                            >
                              #contact
                            </Tag>
                            , &nbsp;
                            <Tag
                              color="green"
                              className="extension-tag"
                              onClick={() =>
                                handleDealNameTagClick("#firstname")
                              }
                            >
                              #firstname
                            </Tag>
                            , &nbsp;
                            <Tag
                              color="green"
                              className="extension-tag"
                              onClick={() =>
                                handleDealNameTagClick("#lastname")
                              }
                            >
                              #lastname
                            </Tag>
                            &nbsp; and &nbsp;
                            <Tag
                              color="green"
                              className="extension-tag"
                              onClick={() => handleDealNameTagClick("#company")}
                            >
                              #company
                            </Tag>
                            &nbsp; will be replaced with the actual deal's
                            contacts and company name.
                          </p>
                        </div>
                        <div className="extension-block">
                          <ExtensionTableItem label="Create all notes about Job in Deal">
                            <Controller
                              control={control}
                              name="create_all_notes_in_deal"
                              render={({ field: { onChange, value, ref } }) => (
                                <FormSwitch
                                  className="extension-switch"
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  ref={ref}
                                  disabled={isCRMElementDisabled}
                                  onChange={onChange}
                                  isChecked={!!value}
                                />
                              )}
                            />
                          </ExtensionTableItem>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}

          <FormButton
            className="extension-form-button primary-ex-btn"
            htmlType="submit"
            loading={isProcessingForm}
            disabled={!isValid || isProcessingForm}
            onClick={handleSubmit(handleFormSubmit)}
          >
            Save
          </FormButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
