import React, { useEffect, useState } from "react";
import "./extension-ai-job-settings-form.scss";
import { ExtensionTableItem } from "../extension-table-item";
import {
  IGetSettingsResponse,
  IJobAISettings,
  IResponseError,
} from "../../data-access";
import { Controller, useForm } from "react-hook-form";
import { FormButton, FormSwitch } from "../../ui";
import { handleFailedRequest, notify } from "../../util";
import { extensionApi } from "../../api/extension-api";
import { Loader } from "../../ui/loader";

interface ICrmSettingsFormState {
  [key: string]: {
    title: string;
    value: boolean;
    expandable: boolean;
  };
}

interface IExtensionCrmSettingsFormProps {
  refreshToken: string;
}

export const ExtensionAIJobSettingsForm = ({
  refreshToken,
}: IExtensionCrmSettingsFormProps): JSX.Element => {
  const [isFetchingSettings, setIsFetchingSettings] = useState<boolean>(true);
  const [basedSettings, setBasedSettings] = useState<IJobAISettings>({
    automatic_job_ai_assistant: false,
  });
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  const [formSettings] = useState<ICrmSettingsFormState>({
    automatic_job_ai_assistant: {
      title: "Enable automatically AI Job Assistant",
      value: basedSettings.automatic_job_ai_assistant,
      expandable: false,
    },
  });
  const form = useForm<IJobAISettings>({
    mode: "all",
    defaultValues: {
      automatic_job_ai_assistant: basedSettings.automatic_job_ai_assistant,
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isValid },
  } = form;

  useEffect(() => {
    setIsFetchingSettings(true);
    extensionApi
      .getSettings(refreshToken)
      .then((response: IGetSettingsResponse) => {
        setBasedSettings(response.data.job_assistant);
        reset(response.data.job_assistant);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, undefined, error.reason);
      })
      .finally(() => setIsFetchingSettings(false));
  }, []);

  const handleFormSubmit = (formFields: IJobAISettings): void => {
    setIsProcessingForm(true);

    extensionApi
      .updateAIJobSettings(refreshToken, formFields)
      .then((response: { data: IGetSettingsResponse }) => {
        notify("success", "AI Job Assistant Settings has been updated!");
        setBasedSettings(response.data.data.job_assistant);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text);
      })
      .finally(() => setIsProcessingForm(false));
  };

  return (
    <>
      {isFetchingSettings && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}
      {!isFetchingSettings ? (
        <>
          {Object.keys(formSettings).map((key: string) => {
            const settingKey = key as keyof IJobAISettings;

            return (
              <div className="column" key={settingKey}>
                {!formSettings[settingKey]?.expandable && (
                  <div className="extension-block">
                    <ExtensionTableItem label={formSettings[settingKey].title}>
                      <Controller
                        control={control}
                        name={settingKey}
                        render={({ field: { onChange, value, ref } }) => (
                          <FormSwitch
                            className="extension-switch"
                            ref={ref}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            onChange={onChange}
                            isChecked={!!value}
                          />
                        )}
                      />
                    </ExtensionTableItem>
                  </div>
                )}

                {formSettings[settingKey]?.expandable && (
                  <>
                    <div className="extension-block">
                      <ExtensionTableItem
                        label={formSettings[settingKey].title}
                      >
                        <Controller
                          control={control}
                          name={settingKey}
                          render={({ field: { onChange, value, ref } }) => (
                            <FormSwitch
                              ref={ref}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              className="extension-switch"
                              onChange={onChange}
                              isChecked={!!value}
                            />
                          )}
                        />
                      </ExtensionTableItem>
                    </div>
                  </>
                )}
              </div>
            );
          })}

          <FormButton
            className="extension-form-button primary-ex-btn"
            htmlType="submit"
            loading={isProcessingForm}
            disabled={!isValid || isProcessingForm}
            onClick={handleSubmit(handleFormSubmit)}
          >
            Update
          </FormButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
