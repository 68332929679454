import React, { useEffect, useState } from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea";

import "./extension-generate-ai";
import { FormSelect } from "../../ui";
import { FaRegSave } from "react-icons/fa";
import { ExtensionGenerateActions } from "../extension-generate-actions";
import { postExtensionMessage } from "../../util";
import { IPrompt, PostMessageType } from "../../data-access";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { storeSettingsActions } from "../../store/slices/settings";

interface IExtensionGenerateAIProps {
  onUpdate: () => void;
}

export const ExtensionGenerateAI = ({
  onUpdate,
}: IExtensionGenerateAIProps): JSX.Element => {
  const dispatch = useDispatch();
  const promptText = useSelector(
    (state: RootState) => state.settings.promptText
  );
  const promptId = useSelector((state: RootState) => state.settings.promptId);
  const prompts = useSelector((state: RootState) => state.settings.prompts);

  const [text, setText] = useState<string>("");
  const [selectedPromptId, setSelectedPromptId] = useState<string>("");
  const [promptsSelectOptions, setPromptsSelectOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    setText(promptText);
  }, [promptText]);

  useEffect(() => {
    setSelectedPromptId(promptId);
  }, [promptId]);

  useEffect(() => {
    const options = prompts.map((prompt: IPrompt) => {
      return {
        value: prompt.id,
        label: prompt.name,
      };
    });
    setPromptsSelectOptions(options);
  }, [prompts]);

  const handleSelectPrompt = (id: string) => {
    const selectedPromptText =
      prompts.find((prompt: IPrompt) => prompt.id === id)?.prompt || "";
    setSelectedPromptId(id);
    setText(selectedPromptText);

    dispatch(storeSettingsActions.setPromptId(id));
    dispatch(storeSettingsActions.setPromptText(selectedPromptText));
    dispatch(storeSettingsActions.setPromptEditabledText(selectedPromptText));
  };

  const handleOpenPromptsSidebar = () => {
    postExtensionMessage("open-prompts-sidebar" as PostMessageType, {
      isOpen: true,
    });
  };

  const handleChangeText = (text: string) => {
    setText(text);
    dispatch(storeSettingsActions.setPromptEditabledText(text));
  };

  return (
    <div className="extension-cover-letter__content">
      <div className="extension-cover-letter__filters">
        <FormSelect
          value={selectedPromptId}
          placeholder="None"
          options={promptsSelectOptions}
          onChange={(id) => handleSelectPrompt(id)}
        />
        <button
          className="extension-btn primary-ex-btn"
          onClick={handleOpenPromptsSidebar}
        >
          Prompts Manager
        </button>
      </div>
      <div className="extension-cover-letter__area">
        <HighlightWithinTextarea
          placeholder=""
          value={text}
          highlight={[
            {
              highlight: /\[(.*?)\]/g,
              className: "highlight-text",
            },
          ]}
          onChange={(text: string) => handleChangeText(text)}
        />

        <button
          className="extension-btn primary-ex-btn save-btn"
          onClick={onUpdate}
        >
          <FaRegSave />
        </button>
      </div>

      <ExtensionGenerateActions />
    </div>
  );
};
